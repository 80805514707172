header.main-menu {
  font-size: 14px;

  .container {
    max-width: 1420px;
  }

  nav.navbar {
    padding: 23px 0;
    padding-bottom: 50px;

    .container-fluid {
      padding: 0;
    }

    img {
      max-width: 184px;
    }

    .navbar-toggler {
      border: 0;

      &:focus, &:focus-visible {
        box-shadow: none;
        border: 0;

      }
    }

    #top-navbar {
      .nav-item {
        padding: 0 1rem;

        @media (min-width: 992px) and (max-width: 1149px) {
          padding: 0;
        }

        .btn {
          max-width: 300px;
          margin: 0 auto;
        }
      }

      a.nav-link {
        color: $white;

        &:hover, &:active, &:focus, &:active:focus, &.active {
          color: $color_cyan_text;
        }

        &.btn {
          color: $color_blue_main_text;
          padding: 8px 24px;
          font-size: 14px;
          border-radius: 25px;

          &:hover {
            color: $color_blue_main_text;
          }

          &:active, &:focus, &:active:focus {
            color: $color_cyan_very_light;
            text-decoration: none;
          }
        }
      }

      .form-select {
        width: auto;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%2369EBD0' fill-opacity='0.0' stroke='%2369EBD0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
        cursor: pointer;
        color: $color_cyan_text;;
        font-size: 14px;
        border: 0;

        &:visited, &:focus, &:focus-visible {
          box-shadow: none;
          text-shadow: none;
          outline: 0;
          text-decoration: none;
        }

        option {
          color: $white;

          &:first-child {
            color: $color_cyan_text;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .navbar-nav {
      font-size: 20px;
    }

    .form-select {
      margin: 20px auto 0;
    }
  }
}
