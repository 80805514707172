body.page-template-page-contact-us {
  .hero-section {
    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    a, a:hover, a:focus, a:visited {
      color: $color_cyan_text;
      text-decoration: underline;
    }

    .form-control, .form-select {
      color: $color_cyan_very_light;
      background-color: #1E3C6F;
      border-color: $color_cyan_dark;
      border-radius: 20px;

      &:focus {
        color: $color_cyan_very_light;
        background-color: transparent;
        border-color: $color_cyan_dark;
        box-shadow: none;
      }
    }

    .form-select {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      option {
        color: $black;
      }
    }

    .form-check-input:focus {
      border: 0;
      box-shadow: none;
    }

    .form-check-input[type="checkbox"] {
      border-radius: 0;
      background-color: #343a40;

      &:checked {
        background-color: #1E3C6F;
      }
    }
  }

}
