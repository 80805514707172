body {
  font-family: $font_medium;
}
a {
  text-decoration: none;

  &:hover, &:focus {
    //color: #FEAB04;
  }
}

.text-color-blue {
  color: $color_blue_main_text;
}

.text-color-white {
  color: $white;
}

.text-color-cyan {
  color: $color_cyan_text;
}

.text-color-cyan-light {
  color: $color_cyan_very_light;
}

.font-medium {
  font-family: $font_medium;
  font-weight: normal;
}

.font-semi-bold {
  font-family: $font_semi_bold;
  font-weight: normal;
}

.font-bold {
  font-family: $font_bold;
  font-weight: normal;
}

.main-content-padding {
  @media (min-width: 1400px) {
    padding: 0 116px;
  }
}

.bg-blue-gradient {
  background: $color_blue_main_bg;
  background-image: -webkit-linear-gradient(top, $color_blue_main_text, $color_blue_main_bg);
  background-image: -moz-linear-gradient(top, $color_blue_main_text, $color_blue_main_bg);
  background-image: -ms-linear-gradient(top, $color_blue_main_text, $color_blue_main_bg);
  background-image: -o-linear-gradient(top, $color_blue_main_text, $color_blue_main_bg);
  background-image: linear-gradient(to bottom, $color_blue_main_text, $color_blue_main_bg);
}

.bg-cyan-gradient {
  background: $white;
  background-image: -webkit-linear-gradient(top, $color_cyan_very_light, $white);
  background-image: -moz-linear-gradient(top, $color_cyan_very_light, $white);
  background-image: -ms-linear-gradient(top, $color_cyan_very_light, $white);
  background-image: -o-linear-gradient(top, $color_cyan_very_light, $white);
  background-image: linear-gradient(to bottom, $color_cyan_very_light, $white);
}

.button-link {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  padding: 0 25px;
}

.button-cyan {
  color: $color_blue_main_text;
  background: $color_cyan_text;
  background-image: -webkit-linear-gradient(top, $color_cyan_light, $color_cyan_text);
  background-image: -moz-linear-gradient(top, $color_cyan_light, $color_cyan_text);
  background-image: -ms-linear-gradient(top, $color_cyan_light, $color_cyan_text);
  background-image: -o-linear-gradient(top, $color_cyan_light, $color_cyan_text);
  background-image: linear-gradient(to bottom, $color_cyan_light, $color_cyan_text);
  border-color: $color_cyan_text;
  box-shadow: 6px 12px 24px #07275FA5;

  &:hover {
    color: $color_blue_main_text;
    background: $color_cyan_text;
    background-image: -webkit-linear-gradient(top, $color_cyan_very_light, $color_cyan_light);
    background-image: -moz-linear-gradient(top, $color_cyan_very_light, $color_cyan_light);
    background-image: -ms-linear-gradient(top, $color_cyan_very_light, $color_cyan_light);
    background-image: -o-linear-gradient(top, $color_cyan_very_light, $color_cyan_light);
    background-image: linear-gradient(to bottom, $color_cyan_very_light, $color_cyan_light);
    border-color: $color_cyan_light;
    box-shadow: 6px 12px 24px #07275F27;
  }

  &:active, &:focus, &:active:focus {
    color: $color_cyan_very_light;
    background: $color_cyan_text;
    background-image: -webkit-linear-gradient(top, $color_cyan_dark, $color_cyan_darker);
    background-image: -moz-linear-gradient(top, $color_cyan_dark, $color_cyan_darker);
    background-image: -ms-linear-gradient(top, $color_cyan_dark, $color_cyan_darker);
    background-image: -o-linear-gradient(top, $color_cyan_dark, $color_cyan_darker);
    background-image: linear-gradient(to bottom, $color_cyan_dark, $color_cyan_darker);
    border-color: $color_cyan_dark;
    box-shadow: 6px 12px 24px #07275F27;
  }
}

//input[type="text"]:focus, input[type="password"]:focus, input[type="email"]:focus, input[type="tel"]:focus, input[type="search"]:focus, input[type="url"]:focus, select:focus, textarea:focus {
//  border-color: #FEAB04;
//  -webkit-box-shadow: 0 0 0 1px #FEAB04;
//  box-shadow: 0 0 0 1px #FEAB04;
//}
