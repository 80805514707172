body.page-template-page-how-it-works {
  .hero-section {
    .hero-text {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    img {
      max-width: 250px;

      @media (min-width: 992px) and (max-width: 1399px) {
        max-width: 320px;
      }

      @media (min-width: 1400px) {
        max-width: 100%;
      }
    }

    .dots-row {
      position: relative;
      padding-top: 70px;

      .col {
        display: flex;
        justify-content: center;
      }

      .middle-dots-left, .middle-dots-right {
        display: block;
        position: absolute;
        top: 5px;
        width: 180px;
        height: 130px;
        background-image: url('../../img/single_dot.png');
      }

      .middle-dots-left {
        left: 0;
      }

      .middle-dots-right {
        right: 0;
      }
    }
  }

  .second-section {
    padding-top: 40px;
    font-size: 20px;

    @media (min-width: 992px) {
      padding-top: 62px;
    }

    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    .row {
      position: relative;
    }

    img {
      max-width: 250px;

      @media (min-width: 992px) and (max-width: 1399px) {
        max-width: 350px;
      }

      @media (min-width: 1400px) {
        max-width: 70%;
      }
    }

    .second-section-dots {
      position: absolute;
      left: 50%;
      bottom: -165px;
      width: 180px;
      height: 180px;

      div {
        width: 180px;
        height: 180px;
        position: relative;
        left: -66%;
        display: block;
        background-image: url('../../img/single_dot.png');
      }
    }
  }

  .bottom-section {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 20px;

    @media (min-width: 992px) {
      padding-top: 75px;
      padding-bottom: 50px;
    }

    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    img {
      max-width: 250px;

      @media (min-width: 992px) and (max-width: 1399px) {
        max-width: 400px;
      }

      @media (min-width: 1400px) {
        max-width: 70%;
      }
    }

    .bottom-link {
      @media (min-width: 992px) {
        margin-top: -30px;
      }

      .btn {
        padding: 8px 20px;
        font-size: 13px;
        border-radius: 25px;
        box-shadow: 6px 12px 24px #07275F27;

        @media (min-width: 992px) {
          font-size: 15px;
        }

        @media (min-width: 1399px) {
          padding: 12px 25px;
          font-size: 16px;
        }
      }
    }
  }
}
