body.page-template-page-home {
  .hero-section {
    .hero-text {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    .carousel-item {
      img {
        position: relative;
        z-index: 999;
        margin: auto;
        max-width: 200px;

        @media (min-width: 992px) {
          margin: auto 0 auto auto;
        }

        @media (min-width: 992px) and (max-width: 1399px) {
          max-width: 350px;
        }

        @media (min-width: 1400px) {
          max-width: 100%;
        }

      }
    }

    .slider-dots {
      display: block;
      position: absolute;
      background-image: url('../../img/single_dot.png');

      @media (min-width: 992px) {
        width: 160px;
        height: 288px;
        right: -4px;
        bottom: 28px;
      }

      @media (min-width: 1400px) {
        width: 180px;
        height: 350px;
        bottom: 65px;
      }
    }

    .button-links-row {
      text-align: center;

      .button-link {
        height: 35px;
        font-size: 13px;
        border-radius: 25px;

        @media (min-width: 768px) {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
        @media (min-width: 992px) and (max-width: 1399px) {
          height: 40px;
          font-size: 15px;
        }

         @media (min-width: 1400px) {
           height: 50px;
           font-size: 16px;
         }
      }
    }

    .dots-row {
      margin-top: 60px;
      padding-bottom: 29px;
      position: relative;

      .col {
        display: flex;
        justify-content: center;
      }

      .middle-dots-left, .middle-dots-right {
        display: block;
        position: absolute;
        top: -35px;
        width: 180px;
        height: 130px;
        background-image: url('../../img/single_dot.png');
      }

      .middle-dots-left {
        left: 0;
      }

      .middle-dots-right {
        right: 0;
      }

    }
  }

  .middle-section {
    padding-top: 40px;
    font-size: 20px;

    @media (min-width: 992px) {
      padding-bottom: 100px;
    }

    @media (max-width: 991px) {
      img {
        max-width: 300px;
      }
    }

    .row {
      position: relative;
    }

    .blurb {
      @media (min-width: 992px) {
        padding-top: 100px;
      }
    }

    .blurb-button {
      padding-top: 2rem;

      .button-blurb {
        padding: 8px 20px;
        font-size: 13px;
        border-radius: 25px;
        box-shadow: 6px 12px 24px #07275F27;

        @media (min-width: 992px) {
          font-size: 15px;
        }

        @media (min-width: 1399px) {
          padding: 12px 25px;
          font-size: 16px;
        }
      }
    }

    .bottom-dots {
      position: absolute;
      left: 50%;
      bottom: -193px;
      width: 180px;
      height: 180px;

      @media (min-width: 1400px) {
        bottom: -92px;
      }

      div {
        width: 180px;
        height: 180px;
        display: block;
        position: relative;
        left: -66%;
        background-image: url('../../img/single_dot.png');
      }
    }
  }

  .bottom-section {
    padding-top: 40px;
    padding-bottom: 92px;
    font-size: 20px;

    @media (min-width: 992px) {
      padding-top: 130px;
    }

    @media (min-width: 1400px) {
      margin-top: -100px;
    }

    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    img {
      max-width: 300px;

      @media (min-width: 992px) {
        max-width: 70%;
      }
    }

    .blurb {
      padding-top: 20px;
      font-size: 20px;
    }

    .blurb-button {
      padding-top: 30px;

      @media (min-width: 992px) {
        padding-top: 30px;
      }

      .button-blurb {
        padding: 8px 20px;
        font-size: 13px;
        border-radius: 25px;
        box-shadow: 6px 12px 24px #07275F27;

        @media (min-width: 992px) {
          font-size: 15px;
        }

        @media (min-width: 1399px) {
          padding: 12px 25px;
          font-size: 16px;
        }

      }
    }
  }

}
