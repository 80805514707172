footer.footer-section {
  background: #031026;
  border: 1px solid #07275F;
  font-size: 11px;
  padding-top: 31px;
  padding-bottom: 37px;

  .quick-link {
    font-size: inherit;
    color: inherit;
    text-decoration: none;
    padding-left: 30px;

    &:first-child {
      padding-left: 0;
    }

    &:hover, &:active, &:focus, &:active:focus {
      text-decoration: underline;
      color: inherit;
    }
  }
}
