body.page-template-page-travel-hospitality {
  .hero-section {
    .hero-row {
      @media (min-width: 992px) {
        padding-bottom: 75px;
      }
    }

    .hero-text {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 38px;
      }
    }

    img {
      max-width: 250px;

      @media (min-width: 992px) and (max-width: 1399px) {
        max-width: 350px;
      }

      @media (min-width: 1400px) {
        max-width: 65%;
      }
    }

    .dots-row {
      margin-top: -50px;
      padding-bottom: 29px;
      position: relative;

      .col {
        display: flex;
        justify-content: center;
      }

      .middle-dots-left, .middle-dots-right {
        display: block;
        position: absolute;
        top: -35px;
        width: 180px;
        height: 130px;
        background-image: url('../../img/single_dot.png');
      }

      .middle-dots-left {
        left: 0;
      }

      .middle-dots-right {
        right: 0;
      }
    }
  }

  .second-section {
    padding-top: 40px;
    font-size: 20px;

    @media (min-width: 992px) {
      padding-top: 62px;
    }

    .row {
      position: relative;

      &.stats-row {
        padding-bottom: 20px;

        @media (min-width: 992px) {
          padding-bottom: 120px;
        }
      }
    }

    .blurb {
      padding-top: 100px;
    }

    .stat-icon {
      img {
        max-width: 95px;

        @media (min-width: 992px) {
          max-width: 124px;
        }
      }
    }

    .stat-figure {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 44px;
      }
    }

    .second-section-dots {
      position: absolute;
      left: 50%;
      bottom: -92px;
      width: 180px;
      height: 180px;

      div {
        width: 180px;
        height: 180px;
        position: relative;
        left: -66%;
        display: block;
        background-image: url('../../img/single_dot.png');
      }
    }
  }

  .third-section {
    font-size: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 992px) {
      padding-top: 130px;
      padding-bottom: 60px;
    }

    .graphic {
      img {
        max-width: 250px;

        @media (min-width: 992px) {
          max-width: 350px;
        }
      }
    }
    .dots-row {
      position: relative;

      .col {
        display: flex;
        justify-content: center;
      }

      .middle-dots-left, .middle-dots-right {
        display: block;
        position: absolute;
        top: -5px;
        width: 180px;
        height: 130px;
        background-image: url('../../img/single_dot.png');
      }

      .middle-dots-left {
        left: 0;
      }

      .middle-dots-right {
        right: 0;
      }

    }
  }

  .partners-section {
    padding-top: 40px;
    padding-bottom: 40px;

    @media (min-width: 992px) {
      padding-top: 75px;
      padding-bottom: 130px;
    }

    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    .blurb {
      font-size: 20px;
    }

    .subtitle {
      font-size: 24px;
    }

    .partners-link {
      .btn {
        padding: 8px 20px;
        font-size: 13px;
        border-radius: 25px;
        box-shadow: 6px 12px 24px #07275F27;

        @media (min-width: 992px) {
          font-size: 15px;
        }

        @media (min-width: 1399px) {
          padding: 12px 25px;
          font-size: 16px;
        }
      }
    }

    .dots-row {
      position: relative;

      .partners-section-dots {
        display: block;
        position: absolute;
        left: 50%;
        bottom: -220px;
        width: 180px;
        height: 180px;

        div {
          width: 180px;
          height: 180px;
          position: relative;
          left: -66%;
          display: block;
          background-image: url('../../img/single_dot.png');
        }
      }
    }
  }

  .bottom-section {
    padding-top: 40px;
    padding-bottom: 30px;

    @media (min-width: 992px) {
      padding-top: 100px;
      padding-bottom: 50px;
    }

    .section-title {
      font-size: 25px;

      @media (min-width: 992px) {
        font-size: 32px;
      }

      @media (min-width: 1400px) {
        font-size: 38px;
      }
    }

    .reason {
      font-size: 20px;

      img {
        max-width: 70px;

        @media (min-width: 992px) {
          max-width: 90px;
        }
      }
    }

    .bottom-link {
      .btn {
        padding: 8px 20px;
        font-size: 13px;
        border-radius: 25px;
        box-shadow: 6px 12px 24px #07275F27;

        @media (min-width: 992px) {
          font-size: 15px;
        }

        @media (min-width: 1399px) {
          padding: 12px 25px;
          font-size: 16px;
        }
      }
    }
  }

}
